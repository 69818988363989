import React          from 'react'
import moment         from 'moment'
import logo           from '../static/img/svg/logo.svg'
import arrow          from '../static/img/down-arrow.svg'
import DmsCheckCreate from './DmsCheckCreate'
import cn             from 'classnames'

const divStyle = {
    'minHeight': '52vh',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center'
};

export default function DmsCheckItem({ item: { response: item, loading }, code }) {
   
    const [clinics, showClinics] = React.useState(false)
    const onClick = () => {showClinics(!clinics)}
   
   
    if (loading && item === null)
        return (
            <div style={divStyle}>
                <div>Загрузка...</div>
            </div>
        )
    if (!loading && item === null)
        return (
            <div style={divStyle}>
                <div>Не найден</div>
            </div>
        )
    let img = <img src={item.photoPath} alt="" />
    if (!item.photoPath)
        img = (
            <a href="https://bima.tj/">
                <img width="200px" src={logo}></img>
            </a>
        )
    return (
        <div className="page">
            <div className="info">
                <div className="container info__container">
                    <h2 className="info__title visually-hidden">
                        Сведения о застрахованном лице
                    </h2>
                    <div className="info__personal">
                        <div className="info__photo" style={{
                            backgroundColor: '#FFF', display: 'flex',
                            justifyContent:'center', alignItems: 'center'
                        }}>
                            {img}
                        </div>
                        <div className="info__blocks">
                            <div className="info__information">
                                <span className="information__title">Застрахованное лицо:</span>
                                <span className="information__data">{item.name}</span>
                            </div>
                            <div className="info__information">
                                <span className="information__title">Дата рождения:</span>
                                <span className="information__data">{moment(item.birthDate).format("DD.MM.YYYY")}</span>
                            </div>
                            <div className="info__information">
                                <span className="information__title">Номер полиса:</span>
                                <span className="information__data">{item.policyNumber}</span>
                            </div>
                            <div className="info__information">
                                <span className="information__title">Период страхования:</span>
                                <span className="information__data">
                                    {moment(item.startDate).format("DD.MM.YYYY")}&nbsp;-&nbsp;{moment(item.endDate).format("DD.MM.YYYY")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <DmsCheckCreate qr_value={code} />
                </div>
            </div>
            <section className="limits">
                <div className="container">
                    <h2 className="limits__title">Лимиты по услугам:</h2>
                    {
                        item.risks.map(el => {
                            return (
                                <div className="limits__info" key={item.id}>
                                    <h3 className="limits__service-type">{el.name}</h3>
                                    <ul className="limits__list">
                                        <li className="limits__item">
                                            {/*<span className="limits__name">Остаток:</span>*/}
                                            <span className={cn('limits__value', {'limits__value--exceed': el.limitSum <= 0})}>
                                                {el.limitSum} {el.currency || 'TJS'}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            )
                        })
                    }
                </div>                
            </section>
            <section class="clinics">
            <div class="container">
            <div class="clinics__header" onClick={onClick}>
                <h2 class="clinics__title">Список медицинских учреждений:</h2>
                <img src={arrow} alt="открыть список клиник" className={`clinics__arrow${!clinics ? '' : '_open'}`} />
            </div>
            <div className={`clinics__body${!clinics ? '' : '_open'}`}>
                <div class="clinics__group">
                <div class="clinics__group-title">в г.Душанбе:</div>
                <ul class="clinics__group-list">
                    <li class="clinics__item">Стоматологическая клиника «Виодент»</li>
                    <li class="clinics__item">Стоматологическая клиника «Евродент»</li>
                    <li class="clinics__item">Медико-эстетический центр «Жасмин»</li>
                    <li class="clinics__item">Стоматологическая клиника "Smile"  ( Смайл) ООО "Азиза-2011"</li>
                    <li class="clinics__item">Медицинский центр «Ибн Сино»</li>
                    <li class="clinics__item">Медицинский центр «Шифо»</li>
                    <li class="clinics__item">Клиника ООО "Табиати солим" ООО "Табиати солим"</li>
                    <li class="clinics__item">Диагностический центр «Нурафзо»</li>
                    <li class="clinics__item">Диагностический центр «Диамед»</li>
                    <li class="clinics__item">Диагностический центр «Гемотест»</li>
                    <li class="clinics__item">Стоматологическая клиника «Ором тиб»</li>
                    <li class="clinics__item">ГУ «Республиканский клинический центр профессиональных заболеваний» (Медгородок)          (медосмотр)</li>
                    <li class="clinics__item">Офтальмологическая-хирургическая клиника «Чаррохии Чашм Нигох» ООО "Чаррохии Чашм Нигох"</li>
                    <li class="clinics__item">Медицинский центр «Аритмия» ООО "Аритмия"</li>
                    <li class="clinics__item">Клиника «Шифои Рахмон»</li>
                    <li class="clinics__item">Медицинский Центр «Ахрориён»</li>
                    <li class="clinics__item">Клиника GMC ООО "Маликаи Тиб"</li>
                    <li class="clinics__item">Сеть аптек ООО «Хайём» ООО "Хайём"</li>
                    <li class="clinics__item">Клиника Сафар Музафарович. ООО "Воло-2013"</li>
                    <li class="clinics__item">Клиника пластической хирургии и инновационной лабораторной диагностики «Ахвар - Ахват»</li>
                    <li class="clinics__item">Клиника София. ООО "София Файзиева"</li> 
                    <li class="clinics__item">Стоматология ELITE. ООО "Элита-Стоматолгия"</li>

                </ul>
                </div>
                <div class="clinics__group">
                <div class="clinics__group-title">РРП:</div>
                <ul class="clinics__group-list">
                    <li class="clinics__item">Диагностический центр «Ганчи Шифо»</li>
                    <li class="clinics__item">Лаборатория «Диамед»</li>
                    <li class="clinics__item">Диагностический центр «Гемотест»</li>
                    <li class="clinics__item">Лечебно-диагностический центр «Нури Сино»</li>
                    <li class="clinics__item">ООО «Сияриён»</li>
                </ul>
                </div>
                <div class="clinics__group">
                <div class="clinics__group-title">г. Худжанд:</div>
                <ul class="clinics__group-list">
                    <li class="clinics__item">Диагностический центр «Диамед»</li>
                    <li class="clinics__item">Сеть стоматологических клиник «Садаф Сугд»</li>
                    <li class="clinics__item">Лечебно- диагностическая клиника «Лукмони Хаким»</li>
                    <li class="clinics__item">Лечебно- диагностическая клиника «Тибби Ачам»</li>
                    <li class="clinics__item">Диагностический центр «Микролаб»</li>
                    <li class="clinics__item">Клиника «Синапс»</li>
                    <li class="clinics__item">Турецко-таджикский медицинский центр «Семамед» (ООО «Сема мед Согд»</li>
                    <li class="clinics__item">Международная узбекско-таджикская Кардиологическая клиника «Дастаи Дил»</li>
                    <li class="clinics__item">Международный Узбекский передовой медицинский центр «MARMED»</li>
                    <li class="clinics__item">Семейная стоматологическая клиника «СИФАТ»</li>
                </ul>
                </div>
                <div class="clinics__group">
                <div class="clinics__group-title">Хатлонская область:</div>
                <ul class="clinics__group-list">
                    <li class="clinics__item">Диагностико-лечебный центр «Даво»</li>
                    <li class="clinics__item">Диагностический центр «Акси Шифо»</li>
                    <li class="clinics__item">Диагностический центр «Диамед»</li>
                    <li class="clinics__item">Стоматология "Асадов"</li>
                    <li class="clinics__item">Стоматология "Карим Джурабаев"</li>
                    <li class="clinics__item">Стоматология "ВИНИР" ООО "Лаззати Олам"</li>
                    <li class="clinics__item">Лечебно-диагностический центр-Дурахш ООО "Дурахши Бохтар"</li>
                </ul>
                </div>
                <div class="clinics__group">
                <div class="clinics__group-title">г.Хорог (ГБАО):</div>
                <ul class="clinics__group-list">
                    <li class="clinics__item">Медицинский центр «Aga Khan Health Centre»</li>
                    <li class="clinics__item">Диагностический центр «Диамед»</li>
                    <li class="clinics__item">«Городской центр здоровья»</li>
                </ul>
                </div>
            </div>
            </div>
      </section>
        </div>
    )
}
